import { useContext, useEffect, useRef, useState } from 'react';
import MediaContext, {
    Image,
    imageFromImageOperation,
    ImageOperation,
    imageOperationFromImage,
    isImageOwnedByUser,
    TMedia,
} from '../../contexts/MediaContext';
import MetaContext, {
    MetaData as IMetaData,
    TMeta,
    EmptyMetaData,
    addOrMergeCustom,
    metaFieldForMetaType,
    newMetaField,
    validateMeta,
} from '../../contexts/MetaContext';
import useAuth from '../../hooks/useAuth';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Stack,
    SwipeableDrawer,
    Typography,
    useTheme,
} from '@mui/material';
import { Color } from '../../Color';
import IrdbDrawer from '../general/Drawer';
import { ZIndex } from '../../App';
import { useLocation, useParams } from 'react-router-dom';
import { nullUndefinedOrEmpty } from '../../util/string';
import GrayBoxButton from '../general/GrayBoxButton';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import EventContext, { TEvent, Event } from '../../contexts/EventContext';
import TextField from '../general/TextField';
import UserContext, { TUser } from '../../contexts/UserContext';
import { CardType, MetaContent, MetaType, TCardType, TTitle } from '../../types/MetaTypes';
import AddCustom from './AddCustom';
import SimilarImages from './SimilarImages';
import ArtCardStack from '../metadata/ArtCardStack';
import GeneralCardStack from '../metadata/GeneralCardStack';
import useAnalytics from 'src/hooks/useAnalytics';
import useIsImageOwner from 'src/hooks/useIsImageOwner';
import Cropper from './CropperExtended';
import { Type } from 'src/contexts/Operation';
import useSaveCropped from 'src/hooks/useSaveCropped';
import usePageTitle from 'src/hooks/usePageTitle';
import useScopeMetaErrors, { useScopeMetaErrorsActions } from '../../hooks/metaErrors/useScopeMetaErrors';
import { WithMetaErrorsProvider } from '../../contexts/specialized/MetaErrorsContext';
import ContactCardStack from '../metadata/ContactCardStack';
import FileDropArea from '../general/FileDropArea';
import { imageAccept } from '../../util/reactDropzone';
import IRCodeOwnedDialog from './IRCodeOwnedDialog';

interface Props {
    image?: Image;
    similarImages?: Image[];
    isAdd?: boolean;
    pushToStack?: (component: JSX.Element) => void;
    popFromStack?: () => void;
    onSaved?: (id: string) => void;
    handleAdd?: () => Promise<string | null>;
    onClose: () => void;
}

function Info({
    image: initialImage,
    similarImages: initialSimilarImages = [],
    isAdd: addingFromMobileWeb,
    onSaved,
    onClose,
    handleAdd,
}: Props) {
    const { add: addingFromMobileNative, edit: editingViaUrl, code } = useParams();
    const location = useLocation();
    const theme = useTheme();
    const { imageViewed } = useAnalytics();

    const { publish } = useContext(EventContext) as TEvent;
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm, notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { user, requestTwoFactorAuth } = useContext(UserContext) as TUser;
    const { fetchSimilarImages, save, unsave, remove, status, init, prep, foveate, query, upload, replace } =
        useContext(MediaContext) as TMedia;
    const { save: saveMeta, requestEdit } = useContext(MetaContext) as TMeta;

    const [image, setImage] = useState<Image | undefined>(initialImage);
    const imageRef = useRef<HTMLImageElement>(null);

    const [isPublished, setIsPublished] = useState<boolean>(false);
    const [showAddCustom, setShowAddCustom] = useState(false);
    const [showTransferIrcode, setShowTransferIrcode] = useState(false);

    // Transfer...
    const [email, setEmail] = useState<string>('');
    const [confirmEmail, setConfirmEmail] = useState<string>('');
    // ...Transfer

    const [replacementImageOperation, setReplacementImageOperation] = useState<ImageOperation<any> | null>();
    const isImageChanged = useRef(false); // To avoid unnecessary replace requests when image was not changed
    const [cropperImageOperation, setCropperImageOperation] = useState<ImageOperation<any> | null>();
    const saveCropped = useSaveCropped();

    const isAdded = (addingFromMobileWeb ?? false) || !nullUndefinedOrEmpty(addingFromMobileNative);

    const [meta, setMeta] = useState<IMetaData>(EmptyMetaData);
    const [isMetaLoading, setIsMetaLoading] = useState(false);
    const metaErrorsScopeId = useScopeMetaErrors(state => state.scopeId);
    const hasMetaErrors = useScopeMetaErrors(state => state.hasErrors);
    const {
        setErrors: setMetaErrors,
        setScopeId: setMetaErrorsScopeId,
        clearErrors: clearMetaErrors,
    } = useScopeMetaErrorsActions();
    const [isEditing, setIsEditing] = useState(isAdded || !!editingViaUrl);

    useEffect(() => {
        setMetaErrorsScopeId(image?.imageID ?? 'new');
    }, [setMetaErrorsScopeId, setMetaErrors, image]);

    // cleanup
    useEffect(
        () => () => {
            if (!metaErrorsScopeId) return;
            clearMetaErrors();
        },
        [metaErrorsScopeId, clearMetaErrors],
    );

    useEffect(() => {
        setImage(initialImage);
    }, [initialImage]);

    useAuth(() => {
        if (image && !(addingFromMobileWeb || addingFromMobileNative)) {
            setIsSimilarImagesLoading(true);
            fetchSimilarImages(image)
                .then(similarImages => {
                    setSimilarImages(similarImages.Results);
                })
                .catch(error => {
                    console.error('Failed to get similar images:', error);
                })
                .finally(() => {
                    setIsSimilarImagesLoading(false);
                });

            imageViewed(image);
        } else {
            setSimilarImages([]);
        }
    }, [image]);

    useAuth(() => {
        if (!image) return;
        if (addingFromMobileWeb === true || addingFromMobileNative) {
            setMeta({
                Meta: [
                    {
                        key: crypto.randomUUID(),
                        metaID: 0,
                        metaType: MetaType.Title,
                        metaContent: {
                            title: '',
                        } as TTitle,
                    },
                ],
                EditingAllowed: true,
            });
        } else {
            setMeta({
                Meta: image.metaArray,
                EditingAllowed: image.editingAllowed,
            });
        }
    }, [image]);

    const isImageOwner = useIsImageOwner(image);

    useEffect(() => {
        if (!isAdded && !editingViaUrl && isEditing && isImageOwner === false) {
            setIsEditing(false);
        }
    }, [editingViaUrl, isAdded, isEditing, isImageOwner]);

    // const goToParentPath = () => {
    //     const pathSegments = location.pathname.split('/');
    //     pathSegments.pop();
    //     const newPath = pathSegments.join('/');
    //     navigate(newPath);
    // };

    const [isSimilarImagesLoading, setIsSimilarImagesLoading] = useState(false);
    const [similarImages, setSimilarImages] = useState<Image[]>(initialSimilarImages);
    const [ownedImage, setOwnedImage] = useState<Image>();

    // Ellipsis...

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // ...Ellipsis

    const onSave = async () => {
        if (!isAdded && isImageOwner === false && !code) {
            notify('Error saving metadata', 'You are not the owner of this IRCODE');
            return;
        }
        const { results: metaResults, errors: metaErrors } = validateMeta(meta.Meta);
        if (metaErrors) {
            await notify('Information missing or invalid', 'Please fill in all required fields');
            setMetaErrors(metaErrors);
            return;
        }
        try {
            setShowLoading(true);
            let imageId = image?.imageID;
            if (isAdded) {
                imageId = (await handleAdd?.()) ?? '';
            } else if (image) {
                if (cropperImageOperation) {
                    const { promise } = saveCropped(cropperImageOperation, image);
                    const res = await promise;
                    const newId = res.operation.Results?.Image?.imageID;
                    if (newId) {
                        imageId = newId;
                    }
                    setCropperImageOperation(null);
                } else if (isImageChanged.current && code && (replacementImageOperation || cropperImageOperation)) {
                    const u = upload((cropperImageOperation ?? replacementImageOperation)!, () => {});
                    const uploaded = await u.promise;
                    const r = replace(image.imageID, uploaded, code);
                    const replaced = await r.promise;
                    if (replaced.operation.ErrorMessage) {
                        throw new Error(replaced.operation.ErrorMessage);
                    }
                    const newImage = replaced.operation.Results?.Image;
                    imageId = newImage?.imageID;
                    isImageChanged.current = false;
                }
            }
            if (!imageId) {
                return;
            }

            await saveMeta(imageId, metaResults!, undefined, undefined, code);

            onSaved?.(imageId);
            // If isAdd, reload as non-isAdd
            if (isAdded) {
                // if (add) {
                //     goToParentPath();
                // }
                setIsPublished(true);

                // @ts-ignore
                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.messageHandler) {
                    // @ts-ignore
                    window.webkit.messageHandlers.messageHandler.postMessage({
                        message: 'close',
                    });
                }
            } else {
                notify('Success', 'Your changes have been saved');
                // TODO: Update metadata
                // refresh();
            }

            publish(Event.RefreshImages);
        } catch (error: any) {
            notify('Error saving metadata', error.message);
        } finally {
            setShowLoading(false);
        }
    };

    const onCancel = () => {
        setIsEditing(false);
        setCropperImageOperation(null);
    };

    const imageTitle = image?.metaContent?.title ?? 'Untitled';

    usePageTitle(
        isAdded && !isPublished ? 'Create New IRCODE'
        : image ? imageTitle
        : '',
        { sendPageview: true, revertOnUnmount: true },
    );

    if (!image) return null;

    const handleSave = async () => {
        if (image && image.imageID) {
            try {
                await save(image.imageID);
                publish(Event.RefreshSaved);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleUnsave = async () => {
        if (image && image.imageID) {
            try {
                await unsave(image.imageID);
                publish(Event.RefreshSaved);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const saved = image.save;
    const saveIcon = saved ? 'fa-solid' : 'fa-regular';

    const onChange = (metaType: MetaType, metaContent: MetaContent) => {
        const newMeta = structuredClone(meta) as IMetaData;
        const index = newMeta.Meta.findIndex(m => m.metaType === metaType);
        if (index !== -1) {
            newMeta.Meta[index].metaContent = metaContent;
        } else {
            newMeta.Meta.push(newMetaField(metaType, metaContent));
        }
        setMeta(newMeta);
    };

    const cardType =
        (metaFieldForMetaType(meta.Meta, MetaType.CardType)?.metaContent as TCardType | undefined)?.cardType ??
        CardType.ArtCard;

    const isImagePublic = image.imageStatus === 'publish';

    const toggleImageStatus = async () => {
        try {
            setShowLoading(true);
            await status(image.imageID, isImagePublic ? 'draft' : 'publish');
            publish(Event.RefreshImages);
        } catch (error) {
            console.error('Error while changing status', error);
        } finally {
            handleClose();
            setShowLoading(false);
        }
    };

    const onDrop = async (files: File[]) => {
        const file = files[0];
        if (!file) return;
        try {
            setShowLoading(true);
            const i = init(file, () => {});
            const initialized = await i.promise;
            const [initIO] = initialized;
            if (initIO.operation.ErrorMessage) {
                console.error(initIO.operation.ErrorMessage);
                return;
            }
            const p = prep(initIO);
            const prepped = await p.promise;

            const f = foveate(prepped);
            const foveated = await f.promise;

            const q = query(foveated, () => {});
            const queried = await q.promise;
            if (queried.operation.ErrorMessage) {
                console.error(queried.operation.ErrorMessage);
                return;
            }
            if (queried.operation.Results?.ImageAlreadyExists) {
                const ownedImage = queried.operation.Results.Image;
                const isOwnedByUser = isImageOwnedByUser(ownedImage, user);
                if (!isOwnedByUser || (image && image.imageID !== ownedImage?.imageID)) {
                    setShowLoading(false);
                    setCropperImageOperation(null);
                    setOwnedImage(ownedImage);
                    return;
                }
            }
            setCropperImageOperation(null);
            setReplacementImageOperation(queried);
            isImageChanged.current = true;
        } catch (error) {
            console.error(error);
            await notify('Error uploading image', (error as Error).message ?? error);
        } finally {
            setShowLoading(false);
        }
    };

    const imageUrl =
        cropperImageOperation?.cropped?.url ?? replacementImageOperation?.original?.preview ?? image.imageUrl;

    return (
        <>
            {/*
                This outer box helped with making the content 100% height in the viewport even when not enough content
                It also fix the issue of the AddMetaData screen not showing at the top of the page
            */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                }}
                style={{
                    height: '100dvh',
                }}
            >
                <Stack
                    id="InfoBox"
                    direction="column"
                    // spacing={2}
                    sx={{
                        // For some reason, this container cleared the margin, padding and rounded corners.
                        // m: 0,
                        // p: 0,

                        backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,

                        flexGrow: 1,
                        overflowY: 'auto',
                    }}
                >
                    {/* Header */}
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            p: 2,
                            alignItems: 'center',
                        }}
                    >
                        {/* Header for Create New IRCODE page */}
                        {isAdded && !isPublished ?
                            <Typography
                                sx={{
                                    flexGrow: 1,
                                    pl: 6,
                                    fontFamily: 'Nocturne Serif',
                                    fontSize: '20px',
                                    fontWeight: 400,
                                    textAlign: 'center',
                                    color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                }}
                            >
                                Create New IRCODE
                            </Typography>
                        :   <>
                                {/* or Edit/View header (Avatar, User name, Ellipsis) */}
                                <Avatar
                                    src={image.ImageUser?.profileUrl}
                                    alt={image.ImageUser?.fullName}
                                    sx={{
                                        width: 48,
                                        height: 48,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        flexGrow: 1,
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    }}
                                >
                                    {image.ImageUser?.userName ?? 'Anonymous'}
                                </Typography>
                                {/* Ellipsis... */}
                                {!!meta.EditingAllowed && (
                                    <>
                                        <IconButton onClick={handleOpen}>
                                            <i
                                                className="fa-solid fa-ellipsis-vertical"
                                                style={{
                                                    color: theme.palette.primary.main,
                                                }}
                                            ></i>
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={handleClose}
                                            sx={{
                                                zIndex: ZIndex.ContextMenu,
                                                // zIndex: theme.zIndex.modal,
                                                '& .MuiPaper-root': {
                                                    // backgroundColor: Color.DarkModeBlack,
                                                    // color: Color.White,
                                                    borderRadius: 2,

                                                    '& .MuiMenuItem-root': {
                                                        fontFamily: 'Nunito Sans',
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        lineHeight: '14px',
                                                        letterSpacing: '0.15em',
                                                        textTransform: 'uppercase',
                                                        justifyContent: 'space-between',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    setIsEditing(true);
                                                    handleClose();
                                                }}
                                            >
                                                Edit
                                                <i className="fa-light fa-pen" style={{ paddingLeft: 8 }}></i>
                                            </MenuItem>
                                            <MenuItem onClick={toggleImageStatus}>
                                                Make {isImagePublic ? 'Private' : 'Public'}
                                                <i
                                                    className={`fa-light fa-${isImagePublic ? 'lock' : 'lock-open'}`}
                                                    style={{ paddingLeft: 20 }}
                                                ></i>
                                            </MenuItem>
                                            <MenuItem
                                                sx={{
                                                    color: 'red',
                                                }}
                                                onClick={() => {
                                                    setShowTransferIrcode(true);
                                                    handleClose();
                                                }}
                                            >
                                                Transfer
                                                <i
                                                    className="fa-light fa-arrow-right-from-bracket"
                                                    style={{ paddingLeft: 8 }}
                                                ></i>
                                            </MenuItem>
                                            <MenuItem
                                                sx={{
                                                    color: 'red',
                                                }}
                                                onClick={async () => {
                                                    if (
                                                        await confirm({
                                                            title: 'IRCODE',
                                                            message: 'Are you sure you want to delete this IRCODE?',
                                                        })
                                                    ) {
                                                        setShowLoading(true);
                                                        await remove(image.imageID);
                                                        publish(Event.RefreshImages);
                                                        setShowLoading(false);
                                                        onClose();
                                                    }
                                                }}
                                            >
                                                Delete
                                                <i className="fa-light fa-trash" style={{ paddingLeft: 8 }}></i>
                                            </MenuItem>
                                        </Menu>
                                    </>
                                )}
                                {/* ...Ellipsis */}
                            </>
                        }
                        <IconButton onClick={onClose}>
                            <i
                                className="fa-solid fa-xmark"
                                style={{
                                    color: theme.palette.primary.main,
                                }}
                            ></i>
                        </IconButton>
                    </Stack>
                    {/* ...Header */}

                    {/* The Image */}
                    {!imageUrl && isEditing ?
                        <FileDropArea
                            dropzoneOptions={{
                                accept: imageAccept,
                                onDrop,
                            }}
                        >
                            <Button variant="irdbText" fullWidth>
                                Add an image
                            </Button>
                        </FileDropArea>
                    :   <Box
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                                }}
                            >
                                <div
                                    style={{
                                        zIndex: 1,
                                    }}
                                >
                                    <img
                                        ref={imageRef}
                                        src={imageUrl}
                                        style={{
                                            width: '100%',
                                        }}
                                        alt=""
                                    />
                                </div>
                                {/* Share ircode button */}
                                {typeof navigator.share !== 'undefined' && !(isAdded && !isPublished) && (
                                    <i
                                        className={`fa-regular fa-share fa-xl`}
                                        style={{
                                            position: 'absolute',
                                            // For some reason it seems to take into account the top margin on the parent
                                            top: 24 + 2 * 8,
                                            right: 24,
                                            zIndex: 2,
                                            color: Color.White,
                                        }}
                                        onClick={async () => {
                                            try {
                                                const response = await fetch(image.imageUrl);
                                                const blob = await response.blob();
                                                const file = new File([blob], 'IRCODE.jpeg', {
                                                    type: 'image/jpeg',
                                                });
                                                navigator.share({
                                                    title: 'IRCODE',
                                                    text: 'Check out this IRCODE',
                                                    url: image.webUrl,
                                                    files: file ? [file] : undefined,
                                                });
                                            } catch (error: any) {
                                                console.error(error);
                                            }
                                        }}
                                    />
                                )}
                                {/* Save ircode button */}
                                {!(isAdded && !isPublished) && (
                                    <i
                                        className={`${saveIcon} fa-star fa-xl`}
                                        style={{
                                            position: 'absolute',
                                            // For some reason it seems to take into account the bottom margin on the parent
                                            bottom: 24 + 2 * 8,
                                            right: 24,
                                            zIndex: 2,
                                            color: Color.White,
                                        }}
                                        onClick={async () => {
                                            if (saved) {
                                                await handleUnsave();
                                            } else {
                                                await handleSave();
                                            }
                                            publish(Event.RefreshImages);
                                        }}
                                    />
                                )}
                                {/* Crop image button */}
                                {!isAdded && isEditing && (
                                    <i
                                        className="fa-solid fa-crop fa-xl"
                                        style={{
                                            position: 'absolute',
                                            top: 24 + 2 * 8,
                                            left: 24,
                                            zIndex: 2,
                                            color: Color.White,
                                        }}
                                        onClick={async () => {
                                            setCropperImageOperation(
                                                replacementImageOperation ?? (await imageOperationFromImage(image)),
                                            );
                                        }}
                                    />
                                )}
                                {/* image upload/replace */}
                                {!isAdded && isEditing && (
                                    <FileDropArea
                                        dropzoneOptions={{
                                            accept: imageAccept,
                                            onDrop,
                                        }}
                                        dragValidText={null}
                                        dragInvalidText={null}
                                    >
                                        <i
                                            style={{
                                                position: 'absolute',
                                                bottom: 24 + 2 * 8,
                                                left: 24,
                                                zIndex: 2,
                                                color: Color.White,
                                            }}
                                            className="fa-solid fa-arrow-up-from-bracket fa-xl"
                                        />
                                    </FileDropArea>
                                )}
                            </Box>
                        </Box>
                    }

                    {/* The MetaData */}
                    {isMetaLoading && (
                        <Stack
                            direction="column"
                            spacing={1}
                            sx={{
                                // width: '100%',
                                px: 2,
                            }}
                        >
                            <Skeleton animation="wave" height={20 * 5} width="100%" />
                            <Stack direction="row" spacing={2}>
                                <Skeleton animation="wave" height={20} width="30%" />
                                <Skeleton animation="wave" height={20} width="70%" />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Skeleton animation="wave" height={20} width="30%" />
                                <Skeleton animation="wave" height={20} width="70%" />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Skeleton animation="wave" height={20} width="30%" />
                                <Skeleton animation="wave" height={20} width="70%" />
                            </Stack>
                        </Stack>
                    )}
                    {!isMetaLoading && cardType === CardType.ArtCard && (
                        <ArtCardStack meta={meta.Meta} image={image} isEditing={isEditing} onChange={onChange} />
                    )}
                    {!isMetaLoading && cardType === CardType.Contact && (
                        <ContactCardStack
                            meta={meta.Meta}
                            image={image}
                            isEditing={isEditing}
                            hiddenTypes={[MetaType.CardType, MetaType.Title]}
                            disabledTypes={[MetaType.FirstName, MetaType.LastName]}
                            onChange={onChange}
                        />
                    )}
                    {!isMetaLoading && cardType === CardType.General && (
                        <GeneralCardStack meta={meta.Meta} image={image} isEditing={isEditing} onChange={onChange} />
                    )}

                    {/* TODO: Drop Container */}

                    <Container
                        sx={{
                            p: 2,
                            pr: !isEditing ? 0 : '', // no gap on right for similar imgs
                        }}
                    >
                        {isEditing && cardType !== CardType.Contact && (
                            <GrayBoxButton
                                onClick={() => {
                                    setShowAddCustom(true);
                                }}
                                sx={{
                                    borderStyle: 'dashed',
                                }}
                            >
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <i className="fa-solid fa-plus" style={{}}></i>
                                    <Typography
                                        sx={{
                                            display: 'inline-block',
                                            ml: 0.5,

                                            fontFamily: 'Nunito Sans',
                                            fontSize: 20,
                                            fontWeight: 400,
                                            lineHeight: '28px',
                                            letterSpacing: '0.01em',
                                        }}
                                    >
                                        Add custom field
                                    </Typography>
                                </Box>
                            </GrayBoxButton>
                        )}

                        {(!isAdded || isPublished) && isEditing && (
                            <Stack
                                direction="column"
                                sx={{
                                    mt: 2,
                                }}
                            >
                                <Button
                                    disabled={hasMetaErrors}
                                    variant="irdbGradient"
                                    sx={{
                                        height: '56px',
                                        textTransform: 'none',
                                    }}
                                    onClick={async () => {
                                        try {
                                            await onSave();
                                        } catch (error: any) {
                                            // TODO: Show error
                                            // setErrorMessage(error.message);
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="irdbText"
                                    color="primary"
                                    sx={{
                                        my: 2,
                                        textTransform: 'none',
                                    }}
                                    onClick={onCancel}
                                >
                                    <i className="fa-solid fa-times" />
                                    &nbsp;Close
                                </Button>
                            </Stack>
                        )}

                        {isAdded && !isPublished && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    p: 2,
                                }}
                            >
                                <Button
                                    disabled={hasMetaErrors}
                                    type="submit"
                                    variant="irdbGradient"
                                    sx={{
                                        flexGrow: 1,
                                        height: '56px',
                                        textTransform: 'none',
                                    }}
                                    onClick={async () => {
                                        try {
                                            await onSave();
                                        } catch (error: any) {
                                            // setErrorMessage(error.message);
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        )}
                    </Container>

                    {/* Similar Images */}
                    {!isEditing && (
                        <SimilarImages
                            isSimilarImagesLoading={isSimilarImagesLoading}
                            similarImages={similarImages}
                            setImage={setImage}
                        />
                    )}

                    {!isMetaLoading && !isEditing && cardType === CardType.Contact && (
                        <>
                            <Typography
                                sx={{
                                    mb: 4,
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: darkMode ? Color.TermsGray : Color.PrimaryDarkGrayBlue,
                                }}
                                onClick={async () => {
                                    setShowLoading(true);
                                    await requestEdit(image.imageID);
                                    await notify(
                                        'Check Your Email',
                                        "We've sent a link to customize your contact badge.",
                                    );
                                    setShowLoading(false);
                                }}
                            >
                                Customize My Contact Badge
                            </Typography>
                        </>
                    )}
                </Stack>
            </Box>

            {/* Add Custom */}
            <SwipeableDrawer
                anchor="bottom"
                open={showAddCustom}
                ModalProps={{
                    keepMounted: false,
                }}
                onClose={event => {
                    setShowAddCustom(false);
                }}
                onOpen={event => {}}
                style={{
                    zIndex: ZIndex.AddCustom,
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        height: '100%',
                        overflow: 'scroll',
                        backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                    },
                }}
            >
                <AddCustom
                    onSelection={(field: string) => {
                        const customMetaField = metaFieldForMetaType(meta.Meta, MetaType.Custom);
                        const newMetaField = addOrMergeCustom(customMetaField, field);
                        const newMeta = {
                            ...meta,
                            Meta: [...meta.Meta.filter(m => m.metaType !== MetaType.Custom), newMetaField],
                        };
                        setMeta(newMeta);
                        setShowAddCustom(false);
                    }}
                    onClose={() => {
                        setShowAddCustom(false);
                    }}
                />
            </SwipeableDrawer>

            {/* Transfer IRCODE */}
            <SwipeableDrawer
                anchor="bottom"
                open={showTransferIrcode}
                // ModalProps={{
                //     keepMounted: false,
                // }}
                onClose={event => {
                    setShowTransferIrcode(false);
                }}
                onOpen={event => {}}
                style={{
                    // TODO: Choose correct ZIndex...
                    zIndex: 9999999,
                }}
                sx={{
                    // display: { xs: 'block', sm: 'none' },
                    // TODO: This needs to be global
                    '& .MuiDrawer-paper': {
                        backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                    },
                }}
            >
                <IrdbDrawer>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            height: '100vh',
                            p: 4,
                        }}
                        style={{
                            height: '100dvh',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <IconButton onClick={() => setShowTransferIrcode(false)}>
                                <i
                                    className="fa-solid fa-xmark"
                                    style={{
                                        color: theme.palette.primary.main,
                                    }}
                                ></i>
                            </IconButton>
                        </Box>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                // p: 2,
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={image.imageUrl}
                                style={{
                                    width: 200,
                                    height: 150,
                                    // borderRadius: 24,
                                    objectFit: 'contain',
                                }}
                                alt={imageTitle}
                            />
                            <Typography
                                sx={{
                                    flexGrow: 1,

                                    fontFamily: 'Nocturne Serif',
                                    fontSize: '32px',
                                    fontWeight: 600,
                                    lineHeight: '40px',
                                    letterSpacing: '-0.03em',
                                    textAlign: 'left',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {imageTitle}
                            </Typography>
                        </Stack>
                        <Divider />

                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                // fontSize: '32px',
                                // fontWeight: 600,
                                // lineHeight: '40px',
                                // letterSpacing: '-0.03em',
                                textAlign: 'left',
                                wordBreak: 'break-word',
                            }}
                        >
                            Securely transfer your IRCODE
                        </Typography>
                        <Typography
                            component="div"
                            sx={{
                                fontFamily: 'Nunito Sans',
                                // fontSize: '32px',
                                // fontWeight: 600,
                                // lineHeight: '40px',
                                // letterSpacing: '-0.03em',
                                textAlign: 'left',
                                wordBreak: 'break-word',
                            }}
                        >
                            <p>
                                The email address you transfer to must be associated with an IRCODE account and have
                                two-factor authentication (2FA) set up.
                            </p>
                            <p>
                                Any permanent add-ons will be transferred while subscriptions connected to this IRCODE
                                will be cancelled.
                            </p>
                            <p>The transfer must be accepted within 10 days, or it will be automatically cancelled.</p>
                        </Typography>

                        <TextField
                            label="RECIPIENT EMAIL ADDRESS"
                            value={email}
                            onChange={value => {
                                setEmail(value);
                            }}
                        />

                        <TextField
                            label="CONFIRM RECIPIENT EMAIL ADDRESS"
                            value={confirmEmail}
                            onChange={value => {
                                setConfirmEmail(value);
                            }}
                        />
                        <Button
                            variant="irdbGradient"
                            sx={{
                                height: '56px',
                                textTransform: 'none',
                            }}
                            onClick={async () => {
                                try {
                                    // if (transferIrcode) {
                                    setShowLoading(true);

                                    await requestTwoFactorAuth(
                                        'Confirm you own this number',
                                        user?.phone ?? '',
                                        'Confirm number',
                                    );
                                    setShowLoading(false);
                                    // } else {
                                    //     throw new Error('Please fill out all fields');
                                    // }
                                } catch (error: any) {
                                    notify('Error', error.message);
                                }
                            }}
                            // onClick={async () => {
                            //     try {
                            //         // onSave();
                            //     } catch (error: any) {
                            //         // setErrorMessage(error.message);
                            //     }
                            // }}
                        >
                            <i className="fa-regular fa-lock"></i>
                            Verify Transfer
                        </Button>
                    </Stack>
                </IrdbDrawer>

                {/* Cropper */}
                <Drawer
                    anchor="bottom"
                    open={!!cropperImageOperation && cropperImageOperation.operation.type !== Type.Upload}
                    sx={{
                        zIndex: ZIndex.ImageList,
                    }}
                >
                    <Cropper
                        imageOperation={cropperImageOperation}
                        onProgress={() => {}}
                        upload
                        onSuccess={async i => {
                            setCropperImageOperation(i);
                        }}
                        handleViewExisting={imageOperation => {
                            setIsEditing(false);
                            setCropperImageOperation(null);
                            setImage(imageFromImageOperation(imageOperation));
                        }}
                        onRetake={() => {
                            setCropperImageOperation(null);
                        }}
                        onCancel={() => {
                            setCropperImageOperation(null);
                        }}
                    />
                </Drawer>
            </SwipeableDrawer>
            <IRCodeOwnedDialog
                open={!!ownedImage}
                image={ownedImage}
                ownedByUser={isImageOwnedByUser(ownedImage, user)}
                onCancel={() => setOwnedImage(undefined)}
            />
        </>
    );
}

export default WithMetaErrorsProvider(Info);
